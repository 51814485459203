.App {
    text-align: left;
}

.content {
    margin-top: 66px;
}

.btn {
    margin-right: 5px;
}

.footer {
    background: #f8f9fa !important;
    padding: 20px;
}

.footer-list {
    list-style: none;
    display: inline-block;
    padding: 6px 20px;
}

.footer-list li {
    display: inline-block;
}
